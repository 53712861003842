<template>
    <div>
        <div style="width:114%;text-align:left;padding-left:20px;border-top:solid 1px #ddd;height:52px;line-height:52px;">
            选择因子：<el-radio v-model="radio" label="1" @change="update()">全部</el-radio>
            <el-radio v-model="radio" label="2" @change="single()">单个
                <el-select v-model="value" placeholder="请选择"
                    size="mini"
                    @change="changeimg(parseInt(value))"
                    style="width:100px;height:24px;">
                    <el-option
                        ref="select"
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-radio>
            
        </div>
        <!-- 风格因子累计收益率 -->
        <div class="caption">风格因子累计收益率</div>
        <el-container style="width:115%;height:400px;background:#E4EAF2;padding:0;margin:0;">
            <el-main style="widyh:980px;height:400px;padding:0;margin:0;text-align:left">
                <img :src="syimgsrc" alt="" style="width:980px;height:378px;padding:0;margin:0">
            </el-main>

            <!-- 右侧表格 -->
            <el-aside style="width:190px;height:400px;background:#E4EAF2;border-left:solid 1px #ccc;">
                <el-table class="zj-table" :data="sytableData" :show-header="false" :span-method="arraySpanMethod">
                    <el-table-column prop="name" label="">
                        <template slot-scope="scope">
                            <div v-html="scope.row.name" style="float:left;"></div>
                            <div style="float:left;margin-left:2px;" v-if="showTooltipHandler(scope.row.name)">
                                <el-tooltip placement="top">
                                    <div slot="content" >
                                        <span v-if="showContentHandler(scope.row.name,'贝塔')">股票超额收益率对市场的敏感程度，捕捉在全A市场（国家）因子之外的的市场风险</span>
                                        <span v-if="showContentHandler(scope.row.name,'动量')">描述强势股和弱势股之间的差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'规模')">捕捉大盘股和小盘股的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'盈利')">描述由盈利收益导致的收益率差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'波动')">解释了剥离市场风险后的波动率高低产生的收益率</span>
                                        <span v-if="showContentHandler(scope.row.name,'成长')">描述由盈利增长不同产生的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'价值')">描述股票估值高低不同而产生的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'杠杆')">描述高杠杆和低杠杆股票之间的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'流动性')">解释由股票相对的交易活跃度不同而产生的收益率差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'中盘')">
                                            <p>通常代表中盘股相对大盘股和小盘股的收益差异。</p>
                                            <p>对标准化的规模暴露（流通市值的对数）进行三次方计算，</p>
                                            <p>再将得到的结果对规模因子基于回归权重进行正交化，</p>
                                            <p>最终再将该因子进行极值修正和标准化处理。</p>
                                        </span>
                                    </div>
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>      
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="data" label="" align="right">
                        <template slot-scope="scope">
                            <div v-html="scope.row.data"></div>
                        </template>
                    </el-table-column>
                </el-table> 
            </el-aside>
        </el-container>
        <div style="height:52px"></div>

        <!-- 风格因子实际波动率走势 -->
        <div class="caption">风格因子实际波动率走势</div>
         <el-container style="width:115%;height:400px;background:#E4EAF2;padding:0;margin:0">
            <el-main style="width:980px;height:400px;padding:0;margin:0;text-align:left;">
                <img :src="bdimgsrc" alt="" style="width:980px;height:378px;padding:0;margin:0">
            </el-main>

            <!-- 右侧表格 -->
            <el-aside style="width:190px;height:400px;background:#E4EAF2;border-left:solid 1px #ccc;">
                <el-table class="zj-table" :data="bdtableData" :show-header="false" :span-method="arraySpanMethod">
                    <el-table-column prop="name" label="">
                        <template slot-scope="scope">
                            <div v-html="scope.row.name" style="float:left;"></div>
                            <div style="float:left;margin-left:2px;" v-if="showTooltipHandler(scope.row.name)">
                                <el-tooltip placement="top">
                                    <div slot="content" >
                                        <span v-if="showContentHandler(scope.row.name,'贝塔')">股票超额收益率对市场的敏感程度，捕捉在全A市场（国家）因子之外的的市场风险</span>
                                        <span v-if="showContentHandler(scope.row.name,'动量')">描述强势股和弱势股之间的差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'规模')">捕捉大盘股和小盘股的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'盈利')">描述由盈利收益导致的收益率差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'波动')">解释了剥离市场风险后的波动率高低产生的收益率</span>
                                        <span v-if="showContentHandler(scope.row.name,'成长')">描述由盈利增长不同产生的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'价值')">描述股票估值高低不同而产生的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'杠杆')">描述高杠杆和低杠杆股票之间的收益差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'流动性')">解释由股票相对的交易活跃度不同而产生的收益率差异</span>
                                        <span v-if="showContentHandler(scope.row.name,'中盘')">
                                            <p>通常代表中盘股相对大盘股和小盘股的收益差异。</p>
                                            <p>对标准化的规模暴露（流通市值的对数）进行三次方计算，</p>
                                            <p>再将得到的结果对规模因子基于回归权重进行正交化，</p>
                                            <p>最终再将该因子进行极值修正和标准化处理。</p>
                                        </span>
                                    </div>
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>      
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="data" label="" align="right">
                        <template slot-scope="scope">
                            <div v-html="scope.row.data"></div>
                        </template>
                    </el-table-column>
                </el-table> 
            </el-aside>
        </el-container>

       <!-- <div class="caption">市场总结</div> -->
       <div style="text-align:left;color:#333;font-size:14px;padding:20px;width:110.6%;">
            <div v-html="shichangzongjie" style="font-size:14px"> {{shichangzongjie}} </div>
       </div>
        <!-- 免责声明 -->
       <div style="font-size:12px;margin:10px 0;text-indent:20px;width:115%;border-top:solid 1px #ddd;padding-top:20px;">
           <div  style="float:left;color:#666;">免责声明：</div>
           <div style="float:left;text-align:left;color:#999">
               <p>本图表（含文字）是基于金融市场行情信息和上市公司根据其法定义务公开披露的信息为核心依据的独立第三方研究。我司力求数据及分析客观公正，但不保证其准确性、完整性、及时性等；</p>
               <p>本图表（含文字）中的信息或所表述的意见不构成任何投资建议，我司不对因使用本图表所采取的任何行动承担任何责任。</p>
               <p>如对以上图表有疑问或建议，请联系<a style="color:#72A8E7">ywu@zhijuninvest.com</a>。</p>
               <p>智君科技面向金融机构客户提供基于大数据和人工智能的定制化解决方案，服务涵盖数据产品的咨询、定制、实施与保障。</p>
               <p style="margin-bottom:20px">智君科技聚焦财富管理行业，通过打造专业、智能、合规、安全的产品服务赋能机构客户战略升级。</p>
          </div>
       </div>
    </div>
</template>

<script>
export default {
    name: 'fengge',
    data () {
        return {
            radio: '1',
            syimgsrc:require('../../assets/fengge/shouyilv/shouyilv-全部.png'),
            bdimgsrc:require('../../assets/fengge/bodonglv/bodonglv-全部.png'),
            options: [{
                value: '1',
                label: '贝塔'
            }, {
                value: '2',
                label: '动量'
            }, {
                value: '3',
                label: '规模'
            }, {
                value: '4',
                label: '盈利'
            }, {
                value: '5',
                label: '波动'
            },{
                value: '6',
                label: '成长'
            }, {
                value: '7',
                label: '价值'
            }, {
                value: '8',
                label: '杠杆'
            }, {
                value: '9',
                label: '流动性'
            }, {
                value: '10',
                label: '中盘'
            }],
            value: '',
            factor_cumulative_returns:'',
            bddata:''
        };
    },
    computed: {
        shichangzongjie: function() {
            return this.$store.state.zongjie.content;
        },   
        sytableData: function() {
            return this.$store.state.fengge.sytableData;
        },   
        bdtableData: function() {
            return this.$store.state.fengge.bdtableData;
        },                           
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.dispatch('zongjie/getModelContentByType','fengge')
            this.initdata()
            this.update()
        })             
    },        
    methods: {
        changeimg:function(sortnum){
            this.radio='2'
            switch(sortnum){
                case 1:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-贝塔.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-贝塔.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':0,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':0,'isHushenFont':true})                       
                    break;
                case 2:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-动量.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-动量.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':1,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':1,'isHushenFont':true})                     
                    break;
                case 3:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-规模.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-规模.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':2,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':2,'isHushenFont':true})                     
                    break;
                case 4:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-盈利.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-盈利.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':3,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':3,'isHushenFont':true})                     
                    break;
                case 5:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-波动.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-波动.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':4,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':4,'isHushenFont':true})                     
                    break;
                case 6:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-成长.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-成长.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':5,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':5,'isHushenFont':true})                     
                    break;
                case 7:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-价值.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-价值.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':6,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':6,'isHushenFont':true})                     
                    break;
                case 8:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-杠杆.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-杠杆.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':7,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':7,'isHushenFont':true})                     
                    break;
                case 9:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-流动性.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-流动性.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':8,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':8,'isHushenFont':true})                     
                    break;
                case 10:this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-中盘.png")
                    this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-中盘.png")
                    this.$store.dispatch('fengge/sySingleTable',{'index':9,'isHushenFont':true})
                    this.$store.dispatch('fengge/bdSingleTable',{'index':9,'isHushenFont':true})                     
                    break;
            }
        },
        update(){
            this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-全部.png")
            this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-全部.png")
            this.value="贝塔"
            this.$store.dispatch('fengge/syTableAll',{'isHushenFont':true})
            this.$store.dispatch('fengge/bdTableAll',{'isHushenFont':true})
        },
        single(){
            this.syimgsrc=require("../../assets/fengge/shouyilv/shouyilv-贝塔.png")
            this.bdimgsrc=require("../../assets/fengge/bodonglv/bodonglv-贝塔.png")
            this.$store.dispatch('fengge/sySingleTable',{'index':0,'isHushenFont':true})
            this.$store.dispatch('fengge/bdSingleTable',{'index':0,'isHushenFont':true})                
        },
        initdata(){
            this.$store.dispatch('fengge/getFactorCumulativeReturnForeground',{'isHushenFont':true})
            this.$store.dispatch('fengge/getFactorRealizedVolsChartForeground',{'isHushenFont':true})
        },
        arraySpanMethod({ rowIndex, columnIndex }) {
            if (rowIndex  === 0) {
                if (columnIndex === 0) {
                    return [1, 2];
                } 
            }
        },
        showTooltipHandler(data){
            // debugger
            let substr = data.match(/>(\S*)<\/span>/);
            for(let j = 0,len=this.options.length; j < len; j++) {
                if(substr[1] === this.options[j].label){
                    return true;
                }
            }
            return false;
        },
        showContentHandler(data,yinzi){
            let substr = data.match(/>(\S*)<\/span>/);
            if(substr[1] === yinzi){
                return true;
            }
            return false;
        }           
    }
}
</script>

<style lang='less' scoped>
.caption{
    width:116.5%;
    background:#FEFBEF;
    height:60px;
     text-indent:16px;
    line-height:60px;
    font-size:20px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(0,0,0,1);
    text-align: left;
}

.zj-table{
    background-color: transparent;
    /deep/ .bold{
        font-weight: bold;
    }
    &.el-table /deep/ th, &.el-table /deep/ tr{
        background-color: transparent;
        font-size: 12px;
    }
    &.el-table /deep/ td{
        padding: 5px 0;
        border-bottom: 1px solid #ccc;
        &:first-child{
            padding-left: 5px;
        }
        // &:last-child{
        //     padding-right: 10px;
        // }
        span{
            word-break: keep-all;
        }
    }
}
</style>

<style lang="less">
.el-radio__input.is-checked .el-radio__inner {
    border-color: #5F80A9;
    background: #5F80A9;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #5F80A9;
}
</style>